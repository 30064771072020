<template>
  <div class="modal">
    <div class="modal-bg" @click="close(null, false)"></div>

    <ModalSpinner v-show="ui.loading"></ModalSpinner>

    <div class="modal-block" id="edit-funding-form-modal-block">
      <div class="modal-header" v-show="ui.loading === false">
        <h3>Edit Funding Request</h3>
      </div>
      <form @submit="saveFunding" class="modal-form" v-show="ui.loading === false">
        <div class="modal-body" id="edit-funding-form-modal-body-scroll">
          <div class="modal-body-container mt-3">
            <div class="modal-body-container_title">Loan Information</div>

            <div class="row mt-3">
              <div class="form-group col-md-6 disabled">
                <label for="fieldBorrowerFirstName">Borrower First Name</label>
                <input type="text" id="fieldBorrowerFirstName"
                       v-model="funding.borrowerFirstName"
                       class="form-control disabled" disabled>
              </div>
              <div class="form-group col-md-6 disabled">
                <label for="fieldBorrowerLastName">Borrower Last Name</label>
                <input type="text" id="fieldBorrowerLastName"
                       v-model="funding.borrowerLastName"
                       class="form-control disabled" disabled>
              </div>
              <div class="form-group col-md-6 mt-3 disabled">
                <label for="fieldLender">Lender</label>
                <input type="text" id="fieldLender"
                       v-model="funding.lender"
                       class="form-control disabled" disabled>
              </div>
              <div class="form-group col-md-6 mt-3">
                <label for="fieldLoanNumber">Loan Number</label>
                <input type="text" id="fieldLoanNumber" v-model="funding.loanNumber"
                       v-input-filled:value="funding.loanNumber"
                       class="form-control" autocomplete="off">
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="modal-body-container_title">Payment Details</div>
            <div class="form-group col-md-6">
              <label>Payment Type</label>
              <div class="d-flex">
                  <span class="check-btn" :class="{ active: funding.type === 'wire' }"
                        @click="setType('wire')">Wire</span>
                <span class="check-btn" :class="{ active: funding.type === 'check' }"
                      @click="setType('check')">Check</span>
                <span class="check-btn" :class="{ active: funding.type === 'correspondent' }"
                      @click="setType('correspondent')">Correspondent</span>
              </div>
            </div>
            <div class="form-group col-md-6 mt-3">
              <label for="fieldWireDate">Wire Date</label>
              <date-range-picker
                ref="picker"
                v-model="dateRange"
                class="daterange-control"
                id="fieldWireDate"
                opens="center"
                :singleDatePicker="true"
                :auto-apply="true"
                :timePicker="false"
                :time-picker24-hour="false"
                :ranges="false"
                :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
              >
                <template v-slot:input="picker">
                  {{ picker.startDate | date }}
                  <img src="@/assets/icons/icon-calender.svg" alt="">
                </template>
              </date-range-picker>
            </div>
            <div class="form-group col-md-6 mt-3" v-if="funding.type !== 'correspondent' || isGranted('ROLE_FUNDER')">
              <label for="fieldWireAmount">Wire Amount</label>
              <div class="input-with-icon mb-2 w-75">
                <span class="icon">$</span>
                <input type="number" class="form-control" id="fieldWireAmount"
                       v-input-filled:value="funding.wireAmount"
                       v-model.number="funding.wireAmount"
                       step="0.01">
              </div>
            </div>
            <div class="form-group col-md-6 mt-3">
              <label for="fieldTotalCharged">Total origination charged</label>
              <div class="input-with-icon mb-2 w-75">
                <span class="icon">$</span>
                <input type="number"
                       class="form-control"
                       id="fieldTotalCharged"
                       v-input-filled:value="funding.totalOriginationCharged"
                       v-model.number="funding.totalOriginationCharged"
                       @change="calcTotalDueToSalesperson"
                       step="0.01">
              </div>
            </div>
            <div class="form-group col-md-7 mt-3">
              <label for="fieldReimbursed" class="w-100">How much is being reimbursed for the credit report?</label>
              <div class="input-with-icon mb-2 w-75">
                <span class="icon">$</span>
                <input type="number"
                       class="form-control"
                       id="fieldReimbursed"
                       v-input-filled:value="funding.reimbursedAmount"
                       v-model.number="funding.reimbursedAmount"
                       @change="calcTotalDueToSalesperson"
                       step="0.01">
              </div>
            </div>
          </div>
          <div class="modal-body-container mt-3">
            <label for="reimbursedAppraisal">Are you being reimbursed for the appraisal fee?</label>
            <div class="d-flex" id="reimbursedAppraisal">
              <span class="check-btn" :class="{ active: funding.isAppraisalFee }" @click="funding.isAppraisalFee = true">
                Yes
              </span>
              <span class="check-btn" :class="{ active: !funding.isAppraisalFee }" @click="funding.isAppraisalFee = false">
                No
              </span>
            </div>
            <div class="col-md-6 mt-3" v-if="funding.isAppraisalFee === true">
              <label for="fieldAppraisalFee">
                How much is the appraisal fee/How much of the appraisal is being paid back to you?
              </label>
              <div class="form-group input-with-icon mb-2">
                <span class="icon">$</span>
                <input type="number" class="form-control" id="fieldAppraisalFee"
                       @change="calcTotalDueToSalesperson"
                       v-input-filled:value="funding.appraisalFee"
                       v-model.number="funding.appraisalFee"
                       step="0.01">
              </div>
            </div>
          </div>
          <div class="modal-body-container mt-3">
            <label for="cure">Is there a tolerance cure in this file that was subtracted from the broker check?</label>
            <div class="d-flex" id="cure">
              <span class="check-btn" :class="{ active: funding.isToleranceCure }" @click="funding.isToleranceCure = true">Yes</span>
              <span class="check-btn" :class="{ active: !funding.isToleranceCure }" @click="funding.isToleranceCure = false">No</span>
            </div>
            <div class="col-md-6 mt-3" v-if="funding.isToleranceCure === true">
              <label for="fieldCure">How much is the cure?</label>
              <div class="form-group input-with-icon mb-2">
                <span class="icon">$</span>
                <input type="number" class="form-control" id="fieldCure"
                       @change="calcTotalDueToSalesperson"
                       v-input-filled:value="funding.cureAmount"
                       v-model.number="funding.cureAmount"
                       step="0.01">
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <div class="form-group disabled">
              <label for="fieldTotalDue">Total Due to Salesperson</label>
              <div class="form-group input-with-icon mb-2">
                <span class="icon">$</span>
                <input type="number" class="form-control" id="fieldTotalDue" placeholder="0.00"
                       v-model.number="funding.dueToSalesperson" step="0.01" disabled>
              </div>
            </div>
          </div>
          <div class="modal-body-container">
            <label for="isOrigination">Is this origination from a Lendingtree lead?</label>
            <div class="d-flex" id="isOrigination">
              <span class="check-btn" :class="{active: funding.itIsFromLendingTree}"
                    @click="funding.itIsFromLendingTree = true">Yes</span>
              <span class="check-btn" :class="{active: !funding.itIsFromLendingTree }"
                    @click="funding.itIsFromLendingTree = false; funding.lendingTreeNumber = null">No</span>
            </div>
            <div class="form-group mt-3" v-if="funding.itIsFromLendingTree">
              <label for="fieldLendingTree">QF Number for original lead</label>
              <input type="text" class="form-control" id="fieldLendingTree"
                     v-input-filled:value="funding.lendingTreeNumber"
                     placeholder="LendingTree QF Number" v-model="funding.lendingTreeNumber">
            </div>
          </div>
          <div class="modal-body-container">
            <label for="isReminder">Send Google Review Reminder?</label>
            <div class="d-flex" id="isReminder">
              <span class="check-btn" :class="{ active: funding.isGoogleReminder }" @click="funding.isGoogleReminder = true">Yes</span>
              <span class="check-btn" :class="{ active: !funding.isGoogleReminder }" @click="funding.isGoogleReminder = false">No</span>
            </div>
          </div>
          <div class="modal-body-container">
            <label for="fieldNote">Note</label>
            <textarea id="fieldNote" cols="30" rows="6" placeholder="Write a note (Optional)"
                      v-input-filled:value="funding.notes"
                      class="form-control" v-model="funding.note"></textarea>
          </div>

          <div class="modal-body-container">
            <label>Upload Signed Closing Package</label>
            <file-field-new :files="scannedDoc" field-id="new-funding-scanned-doc"
                       @change="handleFile" />
            <div class="attach-file mt-3" id="settlement" v-if="funding.scannedDocFilename">
              <div>
                <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                <span class="file-name">{{ funding.scannedDocFilename }}</span>
              </div>
              <button>
                <img src="@/assets/icons/icon-download.svg" alt="Download" content="Download" @click="downloadScannedDoc"
                     v-tippy="{arrow: true, placement: 'left-center'}">
              </button>
            </div>

            <label class="mt-4">Upload Signed Initial Disclosures</label>
            <file-field-new :files="initialDisclosure"
                       field-id="new-funding-initial-disclosure"
                       @change="handleInitialDisclosureFile" />
            <div class="attach-file mt-3" id="idfile" v-if="funding.initialDisclosureDocFilename">
              <div>
                <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                <span class="file-name">{{ funding.initialDisclosureDocFilename }}</span>
              </div>
              <button>
                <img src="@/assets/icons/icon-download.svg" alt="Download" content="Download" @click="downloadScannedDoc"
                     v-tippy="{arrow: true, placement: 'left-center'}">
              </button>
            </div>

            <!-- FUNDER, CEO, CEO ASSISTANT, BOOKKEEPER -->
            <div v-if="isGranted('ROLE_FUNDER') || isGranted('ROLE_BOOKKEEPER') || isGranted('ROLE_CEO_ASSISTANT')">
              <label class="mt-4">Upload BCS File</label>
              <file-field-new :files="bcsFile" field-id="funding-bcs-file" @change="handleBCSFile" />
              <div class="attach-file mt-3" id="bcsFile" v-if="funding.bcsFilename">
                <div>
                  <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                  <span class="file-name">{{ funding.bcsFilename }}</span>
                </div>
                <button>
                  <img src="@/assets/icons/icon-download.svg" alt="Download" content="Download"
                       @click="downloadBCSDoc"
                       v-tippy="{arrow: true, placement: 'left-center'}">
                </button>
              </div>
            </div>
          </div>

          <div class="p-3 alert-danger" v-if="!isWireCorrect">
            Looks like wire amount should be {{ convertToCurrency(expectedWireAmount) }}
            but {{ convertToCurrency(givenWireAmount) }} given.
            The wire is the sum of the origination plus reimbursements minus cures – check to see if either your
            origination amount, cure, or your reimbursement is incorrect.
          </div>

          <ValidationErrorsList :error="ui.form.error" :errors="ui.form.errors" :isError="ui.form.isError"/>

        </div>
        <div class="modal-footer d-flex justify-content-start" v-show="ui.loading === false">
          <base-button title="Save" type="submit" action="secondary-default" :disabled="!isWireCorrect" :loading="ui.form.loading" />
          <base-button title="Cancel" action="secondary" @click-btn="close(null, false)" class="ms-2" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker';
import ValidationErrorsList from "../../../components/form/ValidationErrorsList";
import ModalSpinner from "../../../components/modal/ModalSpinner";
import PerfectScrollbar from 'perfect-scrollbar';
import FileFieldNew from '../../../components/FileFieldNew.vue';
import BaseButton from '../../../components/BaseButton.vue';

export default {
  name: "EditFundingModal",
  props: {
    fundingRequestId: Number,
  },
  components: {ModalSpinner, ValidationErrorsList, DateRangePicker, FileFieldNew, BaseButton},
  data() {
    return {
      agents: [],
      loans: [],
      isAgree: false,
      expectedWireAmount: null,
      givenWireAmount: null,
      isWireCorrect: true,
      scannedDoc: [],
      initialDisclosure: [],
      bcsFile: [],
      funding: {},
      dateRange: {
        startDate: new Date(),
        endDate: new Date()
      },
      ui: {
        loading: false,
        loanMultiselectLoading: false,
        ps: null,
        form: {
          loading: false,
          isSuccess: false,
          isError: false,
          error: '',
          errors: [],
        }
      }
    }
  },
  methods: {
    loadFormData() {
      this.ui.loading = true;

      this.$http
        .get(`/api/v1/funding-requests/edit/${this.fundingRequestId}`)
        .then(res => {
          this.funding = res.data.fundingRequest
          this.dateRange.startDate = this.formatDate(this.funding.wireDate, 'YYYY-MM-DD')
          this.agents = res.data.agents
          this.loans = res.data.loans
          this.ui.isAllowedToChangeAuthor = res.data.isAllowedToChangeAuthor
        })
        .finally(() => { this.ui.loading = false })
    },
    saveFunding(e) {
      e.preventDefault();
      this.ui.form.loading = true;
      let formData = new FormData();
      let fr = this.funding
      fr.appraisalFee = fr.isAppraisalFee ? fr.appraisalFee : null
      fr.cureAmount = fr.isToleranceCure ? fr.cureAmount : null
      fr.lendingTreeNumber = fr.itIsFromLendingTree ? fr.lendingTreeNumber : null
      formData.append('loanNumber', fr.loanNumber)
      formData.append('type', fr.type)
      formData.append('wireDate', this.formatDate(this.dateRange.startDate))
      formData.append('wireAmount', fr.wireAmount)
      formData.append('totalOriginationCharged', fr.totalOriginationCharged)
      formData.append('reimbursedAmount', fr.reimbursedAmount)
      formData.append('isAppraisalFee', fr.isAppraisalFee)
      formData.append('appraisalFee', fr.appraisalFee)
      formData.append('isToleranceCure', fr.isToleranceCure)
      formData.append('cureAmount', fr.cureAmount)
      formData.append('dueToSalesperson', fr.dueToSalesperson)
      formData.append('itIsFromLendingTree', fr.itIsFromLendingTree)
      formData.append('lendingTreeNumber', fr.lendingTreeNumber)
      formData.append('note', fr.note)
      formData.append('isPointUpdated', fr.isPointUpdated)
      formData.append('isGoogleReminder', fr.isGoogleReminder)
      formData.append(`scanned_doc`, this.scannedDoc.pop())
      formData.append(`initial_disclosure_doc`, this.initialDisclosure.pop())

      formData.append(`bcsFile`, this.bcsFile.pop())

      this.ui.form.isError = false
      this.ui.form.error = ''
      this.ui.form.errors = []

      this.$http
        .post(`/api/v1/funding-requests/edit/${this.fundingRequestId}`, formData)
        .then(() => this.close(null, true))
        .catch(err => {
          let validation = this.getErrorsFromResponse(err.response)
          this.ui.form.isError = true
          this.ui.form.error = validation.error
          this.ui.form.errors = validation.errors

          let container = this.$el.querySelector("#new-funding-form-modal-block");
          this.alertError(this.getFirstErrorFromResponse(err.response));

          this.$nextTick(() => {
            container.scrollTop = 1000 + container.scrollHeight;
          });
        })
        .finally(() => { this.ui.form.loading = false; })
    },
    setType(type) {
      this.funding.type = type
    },
    calcTotalDueToSalesperson() {
      if (!this.funding.wireAmount || !this.funding.totalOriginationCharged) {
        return
      }
      this.$http
        .post('/api/v1/funding-requests/total-due', {
          totalOriginationCharged: this.funding.totalOriginationCharged,
          reimbursedAmount: this.funding.reimbursedAmount,
          appraisalFee: this.funding.appraisalFee,
          cureAmount: this.funding.cureAmount,
          wireAmount: this.funding.wireAmount,
          agent: this.funding.mlo.id
        })
        .then(res => {
          this.expectedWireAmount = res.data.expectedWireAmount
          this.givenWireAmount = res.data.givenWireAmount
          this.isWireCorrect = res.data.isWireCorrect

          let ignoreByRole = this.isGranted('ROLE_PROCESS_FUNDING_REQUESTS')
            || this.isGranted('ROLE_CEO')
            || this.isGranted('ROLE_CEO_ASSISTANT')
            || this.isGranted('ROLE_CLOSER')

          if (ignoreByRole && this.funding.type === 'correspondent') {
            this.isWireCorrect = true
          } else {
            this.isWireCorrect = res.data.isWireCorrect
          }
          this.funding.dueToSalesperson = res.data.totalDueToSalesperson
        })
    },
    handleFile(files) {
      this.scannedDoc = files;
    },
    handleInitialDisclosureFile(files) {
      this.initialDisclosure = files;
    },
    handleBCSFile(files) {
      this.bcsFile = files;
    },
    downloadScannedDoc() {
      this.goto(this.backendUrl(`/mlo/funding/request/scanned-doc/${this.funding.id}`))
    },
    downloadBCSDoc() {
      this.goto(this.backendUrl(`/mlo/funding/request/bcs-doc/${this.funding.id}`))
    },
    deleteScannedDoc() {
      this.funding.scannedDocFilename = null
    },
    close(id, updateTableData) {
      this.$emit('close', 'editFunding', id, updateTableData)
    }
  },
  beforeMount() {
    this.loadFormData()
  },
  mounted() {
    const scrollbar = document.getElementById('edit-funding-form-modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  },
  filters: {
    date(date) {
      if (date) {
        return moment(String(date)).format('MM/DD/YYYY')
      } else {
        return moment().format('MM/DD/YYYY')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
}
.modal-body-container::v-deep .multiselect {
  &.selected {
    .multiselect__tags {
      background-color: #F3F3F4;
    }

    .multiselect__single {
      background-color: #F3F3F4;
      color: #000000;
      font-size: 14px;
      letter-spacing: 0;
    }

    input {
      background-color: #F3F3F4;
    }
  }

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    padding: 10px 40px 0 15px;
    transition: all .3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
.modal-form {
  position: relative;
  width: 772px;
}

::v-deep {
  .scanned-doc input {
    left: 0;
  }
}

.scanned-doc {
  &::v-deep {
    input {
      left: 0;
    }
  }
}
</style>
