<template>
  <div class="modal" id="fundingDetailsModal">
    <div class="modal-bg" @click="closeModal(null, false)"></div>
    <div class="modal-block">
      <div class="text-center mt-5" v-show="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-header" v-show="ui.loading === false">
        <h3>Funding Request</h3>
        <button class="btn btn-control" v-if="!isGranted('ROLE_ANALYST')" @click="$emit('editFunding')" content="Edit"
                v-tippy="{ placement : 'bottom', arrow : false, arrowType : 'round' }">
          <img src="@/assets/icons/icon-edit-normal.svg" alt="">
        </button>
      </div>
      <div class="modal-body" id="modal-body-scroll" v-show="ui.loading === false">
        <div v-if="ui.loading === false">
          <div class="modal-body-container">
            <div class="row">
              <div class="col-1">
                <v-avatar :username="fundingRequest.borrowerName" :size="42" rounded/>
              </div>
              <div class="col-11 my-auto">
                <span>{{ fundingRequest.borrowerName }}</span>
                <span class="color-light-grey"> by {{ fundingRequest.mlo.fullName }}</span>
              </div>
            </div>
          </div>
          <div class="modal-body-container mb-1"
               v-if="isGranted('ROLE_FUNDING_REQUEST_PROCESS') && fundingRequest.status !== 'paid'">
            <div class="form-group full-width">
              <label for="fieldStatus">Status</label>
              <div class="row">
                <div class="col-md-3 pe-0">
                  <multiselect v-model="formData.status"
                               placeholder="Select Status"
                               id="fieldStatus"
                               :options="formData.statuses"
                               :searchable="true"
                               :close-on-select="true"
                               :allow-empty="false"
                               @select="onSelect"
                               :show-labels="false">
                  </multiselect>
                </div>
                <div class="col-md-6 my-auto ps-3" v-if="ui.saveStatus.isSave === true">
                  <button class="btn btn-primary save-btn" @click="saveStatus">
                  <span class="spinner-border spinner-border-sm text-light"
                        role="status" aria-hidden="true"
                        v-if="ui.saveStatus.isSaving === true">
                  </span>
                    <span class="text-light" v-if="ui.saveStatus.isSaving === false">Save</span>
                  </button>
                </div>
              </div>
              <div class="row" v-if="formData.status === 'error'">
                <div class="col-md-12">
                <textarea id="" class="mt-3" rows="3" placeholder="Error description" aria-label="Error description" v-model="formData.errorDescription">
                </textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="page-tabs d-flex justify-content-between p-0">
            <ul class="tab-list p-0">
              <li @click="tabName = 'Transaction'" :class="{active: tabName === 'Transaction'}">
                Transaction
              </li>
              <li @click="tabName = 'Activity'" :class="{active: tabName === 'Activity'}">
                Activity
              </li>
            </ul>
          </div>
          <div class="modal-body-container mt-4 border-0 pt-2" v-if="tabName === 'Transaction'">
            <div class="modal-body-container_title mt-1" v-if="fundingRequest.status === 'paid' && (isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BOOKKEEPER') || isGranted('ROLE_FUNDER'))">
              Transaction Summary
            </div>
            <div class="table w-100" v-if="fundingRequest.status === 'paid' && (isGranted('ROLE_CEO_ASSISTANT') || isGranted('ROLE_BOOKKEEPER') || isGranted('ROLE_FUNDER'))">
              <table class="table">
                <tbody>
                <tr>
                  <td>Loan Amount</td>
                  <td>{{ convertToCurrency(fundingRequest.transactionSummary.loanAmount) }}</td>
                </tr>
                <tr>
                  <td>Origination</td>
                  <td>{{ convertToCurrency(fundingRequest.totalOriginationCharged) }}</td>
                </tr>
                <tr>
                  <td>Reimbursements</td>
                  <td>{{ convertToCurrency(fundingRequest.transactionSummary.reimbursementAmount) }}</td>
                </tr>
                <tr>
                  <td>Cure</td>
                  <td>{{ fundingRequest.cureAmount ? convertToCurrency(fundingRequest.cureAmount) : '$0.00' }}</td>
                </tr>
                <tr v-for="(tr, key) in fundingRequest.transactionSummary.transactions" :key="key">
                  <td>{{ tr.user}}</td>
                  <td>{{ convertToCurrency(tr.amount) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-body-container_title mt-1">Transaction</div>
            <div class="table w-100">
              <table class="table">
                <tbody>
                <tr>
                  <td>Id</td>
                  <td>{{ fundingRequest.id }}</td>
                </tr>
                <tr>
                  <td>MLO</td>
                  <td>{{ fundingRequest.mlo.fullName }}</td>
                </tr>
                <tr>
                  <td>Processor</td>
                  <td>{{ fundingRequest.processor.fullName }}</td>
                </tr>
                <tr>
                  <td>Total due to salesperson</td>
                  <td>{{ convertToCurrency(fundingRequest.dueToSalesperson) }}</td>
                </tr>
                <tr>
                  <td>Wire/Check Date</td>
                  <td>{{ formatDate(fundingRequest.wireDate) }}</td>
                </tr>
                <tr v-if="fundingRequest.type !== 'correspondent' || isGranted('ROLE_FUNDER')">
                  <td>Wire/Check Amount</td>
                  <td>{{ convertToCurrency(fundingRequest.wireAmount) }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-body-container_title mt-4">Loan Details</div>
            <div class="table w-100">
              <table class="table">
                <tbody>
                <tr>
                  <td>Loan type</td>
                  <td>{{ fundingRequest.loan.productType }}</td>
                </tr>
                <tr>
                  <td>Loan number</td>
                  <td>{{ fundingRequest.loanNumber }}</td>
                </tr>
                <tr>
                  <td>Borrower</td>
                  <td>{{ fundingRequest.borrowerName }}</td>
                </tr>
                <tr>
                  <td>Lender</td>
                  <td>{{ fundingRequest.lender }}</td>
                </tr>
                <tr>
                  <td>Loan Amount</td>
                  <td>{{ convertToCurrency(fundingRequest.loanAmount) }}</td>
                </tr>
                <tr>
                  <td>Total Origination Charged</td>
                  <td>{{ convertToCurrency(fundingRequest.totalOriginationCharged) }}</td>
                </tr>
                <tr>
                  <td>Reimbursed Amount</td>
                  <td>{{ convertToCurrency(fundingRequest.reimbursedAmount) }}</td>
                </tr>
                <tr>
                  <td>Appraisal Fee</td>
                  <td>{{ fundingRequest.appraisalFee ? fundingRequest.appraisalFee : 'None' }}</td>
                </tr>
                <tr>
                  <td>Is Tolerance Cure?</td>
                  <td>{{ fundingRequest.isToleranceCure ? 'Yes' : 'No' }}</td>
                </tr>
                <tr>
                  <td>Cure Amount</td>
                  <td>{{ fundingRequest.cureAmount ? convertToCurrency(fundingRequest.cureAmount) : '-' }}</td>
                </tr>
                <tr>
                  <td>Is it from LendingTree</td>
                  <td>{{ fundingRequest.itIsFromLendingTree ? 'Yes' : 'No' }}</td>
                </tr>
                <tr>
                  <td>LendingTree QF Number</td>
                  <td>{{ fundingRequest.lendingTreeNumber ? fundingRequest.lendingTreeNumber : '-' }}</td>
                </tr>
                <tr>
                  <td>Note</td>
                  <td style="white-space: pre;">{{ fundingRequest.note }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="modal-body-container_title mt-4">Attachments</div>
            <div class="attach-file mt-3" id="settlement" v-if="fundingRequest.scannedDocFilename">
              <div>
                <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                <span class="file-name">{{ fundingRequest.scannedDocFilename }}</span>
              </div>
              <button>
                <img src="@/assets/icons/icon-download.svg" alt="Download" content="Download" @click="downloadScannedDoc"
                     v-tippy="{arrow: true, placement: 'left-center'}">
              </button>
            </div>
            <div class="attach-file mt-3" id="settlement" v-if="fundingRequest.bcsFilename && (isGranted('ROLE_FUNDER') || isGranted('ROLE_BOOKKEEPER') || isGranted('ROLE_CEO_ASSISTANT'))">
              <div>
                <img src="@/assets/icons/icon-attachment-18.svg" alt="Attach icon">
                <span class="file-name">BCS File</span>
              </div>
              <button>
                <img src="@/assets/icons/icon-download.svg" alt="Download" content="Download" @click="downloadBCSDoc"
                     v-tippy="{arrow: true, placement: 'left-center'}">
              </button>
            </div>
          </div>
          <div class="modal-body-container border-0 pt-2" v-if="tabName === 'Activity'">
            <div class="modal-body-container_title mt-4">Activity</div>
            <div class="row w-100 m-0" v-for="(action, index) in activity" :key="index">
              <UserActions :action="action"/>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" v-show="ui.loading === false && !isGranted('ROLE_ANALYST')">
        <button class="btn btn-control" @click="deleteFunding">
          <img src="@/assets/icons/icon-delete.svg" alt="Delete">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import UserActions from "../../../components/UserActions";
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'FundingDetailsModal',
  components: {Multiselect, UserActions},
  props: {
    fundingRequestId: Number,
  },
  data() {
    return {
      ui: {
        loading: false,
        ps: null,
        saveStatus: {
          isSave: false,
          isSaving: false
        }
      },
      fundingRequest: {},
      activity: [],
      formData: {
        status: '',
        statuses: [ 'pending', 'paid', 'error' ],
        errorDescription: ''
      },
      tabName: 'Transaction',
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      this.$http
        .get(`/api/v1/funding_requests/show/${this.fundingRequestId}`)
        .then(res => {
          this.fundingRequest = res.data.fundingRequest
          this.activity = res.data.activity
          if (this.fundingRequest.status === 'waiting_for_payment') {
            this.formData.status = 'pending'
          } else if (this.fundingRequest.status === 'error') {
            this.formData.status = this.fundingRequest.status
            this.formData.errorDescription = this.fundingRequest.errorDescription
          } else {
            this.formData.status = this.fundingRequest.status
          }
          this.ui.loading = false;
        })
    },
    setTab(tabName) {
      this.tabName = tabName;
    },
    onSelect() {
      this.ui.saveStatus.isSave = true;
    },
    saveStatus() {
      this.ui.saveStatus.isSaving = true;
      this.$http
        .post(`/api/v1/funding-requests/update-status/${this.fundingRequest.id}`, {
          status: this.formData.status,
          errorDescription: this.formData.errorDescription
        })
        .then((res) => {
          this.pushAlert(res.data.status, res.data.message)
          this.ui.saveStatus.isSaving = false;
          this.ui.saveStatus.isSave = false;
          this.closeModal(null, true)
        })
      .catch(e => {
        this.alertError(this.getFirstErrorFromResponse(e.response))
      })
    },
    downloadScannedDoc() {
      if (this.fundingRequest.loan.closingDocFilename) {
        this.goto(this.backendUrl(`/mlo/loan/submission/closing-doc/${this.fundingRequest.loan.id}`))
      } else {
        this.goto(this.backendUrl(`/mlo/funding/request/scanned-doc/${this.fundingRequest.id}`))
      }
    },
    downloadBCSDoc() {
      this.goto(this.backendUrl(`/mlo/funding/request/bcs-doc/${this.fundingRequest.id}`))
    },
    deleteFunding() {
      this.$http
        .delete(`/api/v1/funding-requests/delete/${this.fundingRequest.id}`)
        .then(() => this.closeModal(null, true))
    },
    closeModal(id, updateTableData) {
      this.$emit('close', 'fundingDetails', id, updateTableData)
    }
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    const scrollbar = document.getElementById('modal-body-scroll');
    this.ui.ps = new PerfectScrollbar(scrollbar, {
      useBothWheelAxes: false,
      suppressScrollX: true
    });
  },
}
</script>

<style lang="scss" scoped>
#modal-body-scroll {
  position: relative;
  overflow: hidden;
  height: calc(100% - 50px);
  width: 100%;
}
.modal {
  &-body {
    &-container {
      span {
        color: rgba(0, 0, 0, 1);
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 21px;

        &.date {
          opacity: 0.46;
          color: rgba(0, 0, 0, 1);
          font-size: 0.75rem;
          letter-spacing: 0;
          line-height: 1.125rem;
        }

        &.color-light-grey {
          color: rgba(0, 0, 0, 0.46);
          font-size: 0.875rem;
          letter-spacing: 0;
          line-height: 21px;
        }
      }

      .activity-item {
        border-bottom: 0.0625rem solid rgba(231, 232, 232, 1);
        padding: 1.125rem 0.75rem 1.125rem 1.125rem;

        &:nth-child(even) {
          border-radius: 0.25rem;
          background-color: rgba(246, 246, 246, 1);
        }
      }
    }

    table {
      td {
        padding: 8px 0;
      }
    }
  }
}

.save-btn {
  min-height: 44px;
  max-width: 120px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(38, 111, 229, 1);
}

.modal-body-container::v-deep .multiselect {

  &.multiselect--active {
    .multiselect__tags {
      transition: all .3s;
      border: 1px solid rgba(38, 111, 229, 0.5);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__tags {
    padding: 11px 40px 0 8px;
    transition: all .3s;

    &:hover {
      border: 1px solid rgba(38, 111, 229, 0.2);
      box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
    }
  }

  &__select {
    &:before {
      top: 17%;
      position: relative;
      right: 0;
      color: #999;
      border: solid rgba(0, 0, 0, 0.46);
      margin-top: 4px;
      border-width: 0px 1px 1px 0;
      display: inline-block;
      padding: 3px;
      content: "";
      transform: rotate(44deg);
    }
  }

  &__single {
    color: rgba(0, 0, 0, 0.46);
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }
  }

  &__option {
    padding: 10px 12px;
    min-height: 36px;
    transition: all .3s;
    line-height: 20px;
    font-size: 14px;
    color: #000;
    @media screen and (max-width: 568px) {
      font-size: 9px;
    }

    &--highlight {
      background: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }
    }
  }
}
</style>
