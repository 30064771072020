<template>
  <div class="modal" id="globalFilePreviewModal">
    <div class="modal-bg" @click="closeModal"></div>

    <div class="modal-block">
      <div class="modal-header">
        <div class="">
          <h3>
            File Preview
          </h3>
          <div class="sub-title">
            {{ modal.filename }}
          </div>
        </div>
      </div>

      <div class="modal-body">

        <div class="modal-body-container mt-5 border-bottom-0" v-if="!isFileLoaded && !isError">
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>

        <div class="modal-body-container mt-4 border-bottom-0" v-if="isError" >
          <div class="alert alert-danger" role="alert">
            Error during document loading,
            usually it happens if document was removed,
            please refresh page and try again.
          </div>
        </div>

        <div class="modal-body-container mt-4 border-bottom-0" v-if="isFileLoaded && !isError">
          <div class="image-preview-container" v-if="isImage">
            <img :src="modal.url" :alt="modal.filename" style="max-width:100%">
          </div>
          <div class="pdf-preview-container" v-else-if="getFileType === 'pdf'">
            <canvas class="w-100" ref="pdf"></canvas>
          </div>
          <div class="other-preview-container" v-else>
            <div class="alert alert-info" role="alert">
              We can't display preview for {{getFileType}} files,
              but you can download it using button below and open it on your PC
            </div>
          </div>
        </div>

      </div>

      <div class="modal-footer d-flex justify-content-start">
        <button type="submit" class="btn bg-primary submit" @click="downloadFile">
          Download
        </button>
        <button type="reset" class="btn btn-cancel" @click="closeModal">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilePreviewModal",
  data: function () {
    return {
      modal: this.$store.state.filePreviewModal,
      isFileLoaded: false,
      isError: false,
      error: '',
      imageTypes: ['png', 'jpg', 'jpeg', 'gif'],
    }
  },
  computed: {
    getFileType() {
      return this.modal.filename?.split('.').pop().toLowerCase();
    },
    isImage() {
      return this.imageTypes.includes(this.getFileType)
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('CLOSE_FILE_PREVIEW_MODAL')
    },
    downloadFile() {
      let url = new URL(this.modal.url);
      url.searchParams.append('disposition_type', 'attachment');
      window.location.href = url.href;
    },
    preloadImage(imgSrc, callback, error) {
      let imgPreloader = new Image();

      imgPreloader.src = imgSrc;
      if (imgPreloader.complete) {
        callback();
        imgPreloader.onload = () => {
        };
        return;
      }

      imgPreloader.onload = () => {
        callback();
        imgPreloader.onload = () => {
        };
      }

      imgPreloader.onerror = () => {
        error();
        imgPreloader.onerror = () => {
        };
      }
    },
    loadPdf() {
      const pdfjsLib = window.pdfjsLib

      let loadingTask = pdfjsLib.getDocument({
        url: this.modal.url,
        httpHeaders: {
          'api-token': this.$store.getters.getToken ?? ''
        },
      });
      loadingTask.promise
        .then((pdf) => {
          pdf.getPage(1).then((page) => {
            this.isFileLoaded = true
            const scale = 1.2;
            let viewport = page.getViewport({scale: scale,});

            this.$nextTick(() => {
              let context = this.$refs.pdf.getContext('2d');
              this.$refs.pdf.height = viewport.height;
              this.$refs.pdf.width = viewport.width;

              let renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
              page.render(renderContext);
            });
          });
        })
        .catch((e) => {
          console.log(e)
          this.isError = true
          this.isFileLoaded = false
        })

    }
  },
  mounted() {
    if (this.isImage) {
      this.preloadImage(this.modal.url, () => {
        this.isFileLoaded = true
      }, () => {
        this.isError = true
        this.isFileLoaded = false
      })
    } else if (this.getFileType === 'pdf') {
      this.loadPdf()
    } else {
      this.isFileLoaded = true
      this.isError = false
    }
  },
  beforeDestroy() {
    this.$store.dispatch('CLOSE_FILE_PREVIEW_MODAL')
  }
}
</script>
<style scoped lang="scss">

</style>
