<template>
  <div class="modal" id="scoreModal">
    <div class="modal-bg" @click="closeModal(null, false)"></div>
    <div class="modal-block">
      <div class="text-center mt-5" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="modal-header" v-if="ui.loading === false">
        <div class="row w-50">
          <div class="col-2">
            <h3>Score</h3>
          </div>
          <div class="col-4 ps-4 my-auto">
            <div class="score-status score-status_error">{{ capitalize(fundingRequest.statusForHumans) }}</div>
          </div>
        </div>
      </div>
      <div class="modal-body" v-if="ui.loading === false">
        <div class="modal-body-container">
          <div class="row">
            <div class="col-1">
              <v-avatar :username="fundingRequest.borrowerName" :size="42" rounded/>
            </div>
            <div class="col-6 my-auto">
              <span>{{ fundingRequest.borrowerName }}</span>
              <span class="color-light-grey"> by {{ fundingRequest.mlo.fullName }}</span>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0 signed-closing">
          <div class="modal-body-container_title">
            Scanned doc validation result:
          </div>
          <div class="row scanned-result w-100">
            <div class="col-1 my-auto pe-0">
              <img src="@/assets/icons/icon-status-success.svg" alt="success" v-if="scannedDoc.status === 'passed'">
              <img src="@/assets/icons/icon-status-error.svg" alt="error" v-else>
            </div>
            <div class="col-9 my-auto ps-0">
              {{ scannedDoc.message }}
            </div>
            <div class="col-2 my-auto text-end">
              <button class="btn btn-outline-dark btn-view" v-if="fundingRequest.scannedDocFilename"
                      @click="openFilePreview('fr_scannedDocFilename', fundingRequest.id, fundingRequest.scannedDocFilename)">
                View
              </button>
            </div>
          </div>
          <div v-if="fundingRequest.isGranted['askForDocs'] && scannedDoc.status === 'error'">
            <span class="explain">
              Click on button below to change status of this funding to "ERROR", with description "UPLOAD SCANNED DOCS”:
            </span>
            <div class="col-6 mt-2">
              <button class="btn btn-primary" @click="askForScannedDocs">
                Ask for Documents
              </button>
            </div>
          </div>
        </div>

        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">
            Initial Disclosures validation result:
          </div>
          <div class="row scanned-result w-100">
            <div class="col-1 my-auto pe-0">
              <img src="@/assets/icons/icon-status-success.svg" alt="success" v-if="initialDisclosureDoc.status === 'passed'">
              <img src="@/assets/icons/icon-status-error.svg" alt="error" v-else>
            </div>
            <div class="col-9 my-auto ps-0">
              {{ initialDisclosureDoc.message }}
            </div>
            <div class="col-2 my-auto text-end">
              <button class="btn btn-outline-dark btn-view" v-if="fundingRequest.initialDisclosureDocFilename"
                      @click="openFilePreview('fr_initialDisclosureDocFilename', fundingRequest.id, fundingRequest.initialDisclosureDocFilename)">
                View
              </button>
            </div>
          </div>
          <div v-if="fundingRequest.isGranted['askForDocs'] && initialDisclosureDoc.status === 'error'">
            <span class="explain">
              Click on button below to change status of this funding to "ERROR", with description "UPLOAD SCANNED DOCS”:
            </span>
            <div class="col-6 mt-2">
              <button class="btn btn-primary" @click="askForScannedDocs">
                Ask for Documents
              </button>
            </div>
          </div>
        </div>

        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">Total Score: {{ score }}</div>

          <div class="row score-action" v-for="(log, index) in decisionLogs" :key="index">
            <div class="col-1 my-auto pe-0">
              <img src="@/assets/icons/icon-status-ok.svg" alt="" v-if="log.points > 0">
              <img src="@/assets/icons/icon-status-error.svg" alt="" v-else>
            </div>
            <div class="col-8 my-auto ps-0">
              <span>{{ log.msg }}</span>
            </div>
            <div class="col-3 my-auto text-end">
              <span class="color-green ps-2" v-if="log.points">{{ log.points }} points</span>
            </div>
          </div>
        </div>
        <div class="modal-body-container" :class="[ transactions.length > 0 ? 'border-0' : '' ]">
          <div class="modal-body-container_title mb-2">Transactions</div>
          <span class="color-light-grey" v-if="transactions.length === 0">No Transactions were found</span>
          <div class="row transaction-list m-0 p-0" v-else v-for="(tr, index) in transactions" :key="index">
            <div class="transaction-list-item border-bottom-light">
              <div class="row">
                <div class="col-2 my-auto">
                  <div class="col-12">
                    <div class="transaction-list-item_money">{{ convertToCurrency(tr.amount) }}</div>
                    <div class="transaction-list-item_data mt-2">{{ formatDate(tr.postingDate) }}</div>
                  </div>
                </div>
                <div class="col-10">
                  <div class="transaction-list-item_text">
                    <text-highlight :queries="getQueries()">{{ tr.description }}</text-highlight>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FilePreviewModal v-if="$store.state.filePreviewModal.isOpen"></FilePreviewModal>
  </div>
</template>

<script>
import FilePreviewModal from "../../layouts/FilePreviewModal";

export default {
  name: 'ScoreModal',
  components: {FilePreviewModal},
  props: {
    fundingRequestId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      ui: {
        loading: false,
        saveLoading: false
      },
      fundingRequest: {},
      score: 0,
      decisionLogs: [],
      transactions: [],
      scannedDoc: {
        status: null,
        message: null
      },
      initialDisclosureDoc: {
        status: null,
        message: null
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http
        .get(`/api/v1/funding_requests/get-chase-transactions/${this.fundingRequestId}`)
        .then(res => {
          this.fundingRequest = res.data.fundingRequest
          this.score = res.data.score
          this.transactions = res.data.transactions
          this.decisionLogs = res.data.logs
          this.ui.loading = false;
        })
        .catch()
    },
    validateScannedDocs() {
      this.$http
        .get(`/api/v1/funding-requests/validate-scanned-docs/${this.fundingRequestId}`)
        .then(res => {
          this.scannedDoc.status = res.data.status
          this.scannedDoc.message = res.data.message
        })
    },
    validateInitialDisclosureDocs() {
      this.$http
        .get(`/api/v1/funding-requests/validate-initial-disclosure-docs/${this.fundingRequestId}`)
        .then(res => {
          this.initialDisclosureDoc.status = res.data.status
          this.initialDisclosureDoc.message = res.data.message
        })
    },
    getStatusClass() {
      let status = this.fundingRequest.status

      if (status === 'pending') {
        return ''
      } else if (status === 'paid') {
        return 'score-status_paid'
      } else if (status === 'error' || status === 'canceled') {
        return 'score-status_error'
      } else if (status === 'waiting_for_payment') {
        return 'score-status_review'
      }
    },
    askForScannedDocs() {
      this.$http
        .get(`/api/v1/funding-requests/ask-for-scanned-docs/${this.fundingRequestId}`)
        .then(res => {
          this.$store.dispatch('OPEN_STATUS_NOTIFICATION', this.statusNotification = {
            msg: res.data.message,
            status: res.data.status,
            delay: 2000
          });
          this.closeModal(null, true)
        })
    },
    findBorrowerName(word, query) {
      let check = new RegExp(query, "ig");
      return word.toString().replace(check, function (matchedText) {
        return ('<strong>' + matchedText + '</strong>');
      });
    },
    closeModal(id, updateTableData) {
      this.$emit('close', 'scoreModal', id, updateTableData)
    },
    getQueries() {
      let words = [];
      this.fundingRequest.borrowerName.split(', ').forEach(item => words.push(item))
      this.fundingRequest.mlo.fullName.split(' ').forEach(item => words.push(item))

      return words;
    }
  },
  beforeMount() {
    this.getData()
    this.validateScannedDocs()
    this.validateInitialDisclosureDocs()
  }
}
</script>

<style lang="scss" scoped>
.signed-closing {
  margin-bottom: 0;
}

.btn-primary,
.btn-outline-dark {
  max-width: 180px;
  width: 100%;
}

.btn-view {
  max-width: 60px;
  width: 100%;
  height: 32px;
}

.scanned-result {
  padding-bottom: 0.875rem;
  margin: 0 0 0.75rem 0;
  border-bottom: 0.0625rem solid rgba(231, 232, 232, 1);
}

.explain {
  opacity: 0.46;
  color: rgba(0, 0, 0, 1);
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1.125rem;
}

.transaction-list {

  &-item {
    padding: 17px 12px;
    border-bottom: 1px solid rgba(231, 232, 232, 1);

    &_data {
      color: rgba(0, 0, 0, .46);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }

    &_money {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 21px;
    }

    &_text {
      color: rgba(138, 138, 138, 1);
      font-size: 11px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}

.score-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 8px;
  margin: 0 0 0 auto;

  &_error {
    border: 0.0625rem solid rgba(210, 10, 59, 0.4);
    background-color: rgba(210, 10, 59, 0.05);
    color: rgba(210, 10, 59, 1);
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.125rem;
  }

  &_review {
    border: 0.0625rem solid rgba(38, 111, 229, 0.4);
    background-color: rgba(38, 111, 229, 0.05);
    color: rgba(38, 111, 229, 1);
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.125rem;
  }

  &_paid {
    border: 1px solid rgba(0, 171, 52, 0.4);
    background-color: rgba(0, 171, 52, 0.05);
    color: rgba(0, 171, 52, 1);
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.125rem;
  }
}

.score-action {
  margin: 0;
  padding: 14px 0;
  border-bottom: 1px solid rgba(231, 232, 232, 1);
}

</style>
