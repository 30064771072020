<template>
  <div class="col-12 activity-item">
    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-1">
            <v-avatar initials="CMC" rounded :size="42" v-if="isSystemAvatar(action.type)"/>
            <v-avatar :src="agentPhoto(action.author.photo)" rounded :size="42" v-else/>
          </div>
          <div class="col-11 my-auto ps-4" v-if="action.type === 'CloseExpenseTicketUserAction'">
            <span class="color-light-grey">Ticket closed</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'CloseFundingTicketUserAction'">
            <span class="color-light-grey">Ticket closed</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'DeleteExpenseUserAction'">
            <span class="color-light-grey">Expense deleted</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'EditExpenseUserAction'">
            <span class="color-light-grey">
              Expense updated.<br>
              Old amount: {{ convertToCurrency(action.data.oldAmount) }}<br>
              New amount: {{ convertToCurrency(action.data.newAmount) }}<br>
            </span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'EditFundingUserAction'">
            <span class="color-light-grey">
              Funding updated. <br>
              Old wire amount: {{ convertToCurrency(action.data.oldWireAmount) }} <br>
              New wire amount: {{ convertToCurrency(action.data.newWireAmount) }} <br>
            </span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'ExpenseAttachInvoiceUserAction'">
            <span class="color-light-grey">New invoice attached</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'FundingDeleteUserAction'">
            <span class="color-light-grey">Funding deleted</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'FundingErrorUserAction'">
            <span class="color-light-grey">Error - {{ action.data.errorDescription }}</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'FundingPaidUserAction'">
            <span class="color-light-grey">Funding paid</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'FundingRestoreUserAction'">
            <span class="color-light-grey">Funding restored</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'NewExpenseTicketUserAction'">
            <span class="color-light-grey">New ticket opened</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'NewExpenseUserAction'">
            <span class="color-light-grey">Expense submitted</span>
            <span v-if="action.data.expenseAmount !== action.data.originalAmount">
              (Original amount: {{ convertToCurrency(action.data.originalAmount) }})
            </span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'NewFundingTicketUserAction'">
            <span class="color-light-grey">New ticket opened</span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'NewFundingUserAction'">
            <span class="color-light-grey">Funding submitted</span>
            <span v-if="action.data.fundingWireAmount !== action.data.originalWireAmount">
              (Original wire amount: {{ convertToCurrency(action.data.originalWireAmount) }})
            </span>
          </div>
          <div class="col-11 my-auto ps-4" v-else-if="action.type === 'VerifyExpenseUserAction'">
            <span class="color-light-grey">Expense verified</span>
          </div>
        </div>
      </div>
      <div class="col-3 my-auto text-end">
        <span class="date">
          {{ formatDate(action.createdAt, 'MMM DD, Y hh:mm') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserActions",
  props: {action: {type: Object, required: true}},
  methods: {
    isSystemAvatar(actionType) {
      let types = ['FundingErrorUserAction'];
      return types.includes(actionType)
    }
  }
}
</script>

